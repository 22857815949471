.SheetTabs {
  width: 100%;
  user-select: none;
}

.SheetTabs_TabZone {
  cursor: default;
  padding-top: 3px;
}

.SheetTabs_Tab {
  float: left;
  /* Replicate antd buttons */
  /* line-height: 1.499; */
  line-height: 32px; /* same height as div so its centered */
  background: #e4e4e4;
  border: 1px solid #d9d9d9;
  border-radius: 4px 4px 0 0;
  color: rgba(0, 0, 0, 0.65);
  height: 32px;
  padding: 0 7px 0 15px;
  margin-right: 1px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.SheetTabs_Tab--single {
  padding: 0 15px;
}

.SheetTabs_Tab--active {
  background: #fff;
  border-bottom: none;
}

.SheetTabs_TabName {
  margin: 0;
}

.SheetTabs_CloseIcon {
  margin-left: 7px;
  padding-bottom: 1px;
}

.SheetTabs_CloseIcon:hover {
  color: red;
}
