.CategoryMenu {
  padding-top: 5px;
  padding-bottom: 5px;
  background: rgb(87, 105, 127);
  cursor: default;
  user-select: none;
}

.CategoryMenu button {
  cursor: pointer;
  border: none;
  text-align: center;
  display: block;
  margin: auto;
  background: transparent;
  color: #fff;
}

.CategoryMenu button.Selected {
  color: rgb(152, 220, 255);
}

.CategoryMenu button:focus {
  outline: 0;
}
