.PlayControls {
  position: fixed;
  top: 40px;
  right: 15px;
}

.PlayControls button {
  display: block;
  margin-bottom: 0.5em;
}
