.Sheet {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: #fff;
  padding-top: 5px;
}

.Sheet > svg {
  z-index: 1;
}
