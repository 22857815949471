.StaffLine {
  stroke: #000;
  stroke-width: 1px;
  pointer-events: none;
}

.ChordGuideline {
  stroke: #ddd;
  pointer-events: none;
}

.StaffIndex {
  fill: #bbb;
}
