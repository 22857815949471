@import '~antd/dist/antd.css';

.App {
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.App--dragging {
  cursor: grabbing;
  user-select: none;
}

.App--drag {
  cursor: grab;
}

.App--select {
  cursor: default;
}

.App--insert {
  cursor: default;
}

.ant-tabs {
  width: 100%;
  background: #fff;
  user-select: none;
  height: 100vh;
  overflow: auto;
}
